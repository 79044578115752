<template>
  <!-- 编码类型管理 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('codeCat_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="480"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <FormItem label="类型名称" prop="catName">
          <Input
            clearable
            placeholder="请输入类型名称"
            style="width: 300px"
            v-model="form.catName"
          ></Input>
        </FormItem>
        <FormItem label="编码前缀" prop="prefix">
          <Input
            clearable
            placeholder="请输入编码前缀"
            style="width: 300px"
            v-model="form.prefix"
          ></Input>
        </FormItem>
        <FormItem label="编码规则" prop="formula">
          <Input
            clearable
            placeholder="请输入编码规则"
            style="width: 300px"
            v-model="form.formula"
          ></Input>
        </FormItem>
        <FormItem label="简介">
          <Input
            clearable
            placeholder="请输入简介"
            style="width: 300px"
            v-model="form.content"
            type="textarea"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "类型名称",
            width: 180,
            key: "catName",
          },
          {
            title: "前缀",
            width: 180,
            key: "prefix",
          },
          {
            title: "规则",
            key: "formula",
          },
          {
            title: "简介",
            key: "content",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("codeCat_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "类型名称",
              component: "input",
              field: "catName",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        catName: "",
        content: "",
        formula: "",
        prefix: "",
      },
      rules: {
        catName: [{ required: true, message: "请填写类型名称" }],
        formula: [{ required: true, message: "请填写编码规则" }],
        prefix: [{ required: true, message: "请填写编码前缀" }],
      },
      search_data: {},
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("codeCat_check")) return;
      this.table.loading = true;
      this.$post(this.$api.CODE_CAT.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑编码",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.CODE_CAT.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },

    submit() {
      let params = this.form;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(this.$api.CODE_CAT.UPDATE, params)
          .then(() => {
            this.$Message.success("编辑成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        catName: "",
        content: "",
        formula: "",
        prefix: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>